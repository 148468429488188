.data-point {
  opacity: .8;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
}

.data-point:hover{
  opacity: 1;
}


.dissolve {
  animation: dissolve .3s cubic-bezier(.25,.46,.45,.94) .5s both
}


.ping {
  animation: ping 3s ease-in-out infinite both;
}

.slide-bottom {
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-left {
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes dissolve {
  0% {
    opacity: 0
  }
  100%{
    opacity:1
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
