.slide-left {
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@keyframes slide-left {
  0% {
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0);
  }
}