.mediaFormInput input[type="file"] {
    display: none;
}
.mediaFormInput > textarea {
    height: 6.2rem;
}
.deleteIcon {
    cursor: pointer;
}
.fileSelector {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
