.activeLink {
    color: var(--gray);
}

.animated-card div:first-child {
    animation: flip-in-hor-top .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .3s both;
}

.animated-card div:nth-child(2) {
    animation: flip-in-hor-bottom .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .4s both;
}

.animated-card-v-2 div:first-child {
    animation: tilt-in-top-1 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) var(--animation-duration) both;
}
.animated-card-v-2 div:nth-child(2), div:nth-child(3), div:nth-child(4), div:nth-child(5), div:nth-child(6) {
    animation: tilt-in-top-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) var(--animation-duration) both;
}

.notification-cats-card::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom left, rgba(0, 0, 0, .4), rgba(0, 0, 0, .5)), var(--backgroundImage);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1s ease-in-out;
    z-index: -10;
}

.hovering-effect:hover::after {
    transform: scale(1.2);
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-3 12:34:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


@keyframes flip-in-hor-top {
    0% {
        transform: rotateX(-80deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0);
        opacity: 1;
    }
}

@keyframes flip-in-hor-bottom {
    0% {
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0);
        opacity: 1;
    }
}

@keyframes tilt-in-top-1 {
    0% {
      transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
      opacity: 0;
    }
    100% {
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }
  