.carousel-control-prev-icon,
.carousel-control-next-icon {
	color: black;
	position: absolute;
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
}

.carousel-indicators {
	position: absolute;
	color: black
}