.nav-card-wrapper {
  margin-bottom: 10px;
}

.active-card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity .8s ease-in-out;
}

.btn-hovering {
  opacity: 0;
}

.btn-expand {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.btn-expand:hover {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  color: black !important;
  transform: translate(-50%, -50%);
}

.btn-outline:hover {
  color: black !important;
}

.centering {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}


.image-card {
  position: relative;
  object-fit: cover;
  height: auto;
  overflow: hidden;
  cursor: pointer;
}

.image-card img {
  opacity: 1;
  transition: transform 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.image-gradient-overlay {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: linear-gradient(315deg, transparent #000000 0%, #7f8c8d 100%);
}

.task-card-edit-button {
  opacity: 0;
  transition: opacity 0.3s;
}
.task-card:hover .task-card-edit-button {
  opacity: 1;
}

/* .image-card:hover img:not(.modal-image) {
  transform: scale(1.2);
  z-index: -1;
}

.image-card:hover .image-gradient-overlay {
  opacity: .6;
  z-index: 20;
}


.image-card:hover .btn-hovering {
  opacity: 1;
} */


.timer {
  position: absolute;
  z-index: 1000;
}

.column-no-pad {
	padding: 0px;
}

/* .embeded-video {
  opacity: 0;
  transition: opacity 10s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  height: 100%;
} */