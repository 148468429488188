/// Table Styling
.table-empower {
  th {
    text-indent: 1rem;
  }
  td {
    vertical-align: middle;
    text-align: center;
    text-wrap: wrap;
  }
}
.table-button {
  z-index: 10;
  background: transparent;
  cursor: pointer;
  border: none;
  color: #525f7f;
}
.table-button:hover {
  cursor: pointer;
  color: rgb(47, 47, 47);
}
.table-row {
  border-radius: 2rem;
}
.table-row:hover {
  background-color: rgba(109, 123, 201, 0.193) !important;
}

/// Modal Styling
$no-color: rgb(0, 0, 0, 0);
$hovered: rgba(109, 123, 201, 0.193);

$error-dark-red: rgba(245, 54, 92, 0.4);
$success-green: rgba(45, 206, 137, 0.2);

.modal-alert-msg {
  font-size: 18px;
  color: black;
}
.modal-alert-success {
  background-color: $success-green;
}
.modal-alert-error {
  background-color: $error-dark-red;
}

.custom-modal {
  min-height: 95vh;
  max-height: 95vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  overflow-y: hidden !important;
}
.custom-modal > modal-content {
  height: 20%;
  margin-bottom: 2rem;
}
.custom-modal > .modal-dialog {
  margin: 0px;
  height: 95%;
  max-height: 95% !important;
  width: 80vw;
  max-width: 80vw !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-modal-body {
  height: 100%;
  overflow-y: auto;
}
.custom-modal > * .modal-content {
  height: 90vh;
  border-radius: 1rem;
}
.modal-step-buttons {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
}

// Bootstrap Accordion overridden styling
$accordion-border-radius: 7.5px;

.custom-accordion-header {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 0 1rem;
}
.accordion-item {
  border: rgba(0, 0, 0, 0.113) 1px solid;
  border-collapse: collapse;
  border-top: 0px;
  border-bottom: 0px;

  &:first-child {
    border-top-left-radius: $accordion-border-radius;
    border-top-right-radius: $accordion-border-radius;
    border: rgba(0, 0, 0, 0.113) 1px solid;
    border-collapse: collapse;
  }

  &:last-child {
    border-bottom-left-radius: $accordion-border-radius;
    border-bottom-right-radius: $accordion-border-radius;
    border: rgba(0, 0, 0, 0.113) 1px solid;
    border-collapse: collapse;
  }
}
.accordion-button {
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 100ms;
  border: none;
  width: 100%;
  height: 100%;
  background-color: $no-color;
}
.accordion-button:hover {
  background-color: rgba(47, 47, 47, 0.09);
}
.accordion > .card {
  margin-bottom: 3rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid rgb(47, 47, 47) !important;
}
.accordion-task-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.accordion-task-selectable {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 100ms;
  height: 100%;
  margin: 5px 0px;
  padding: 0px 10px;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  border: rgba(0, 0, 0, 0.113) 1px solid;
}
.accordion-task-selected {
  box-shadow: 0px 0px 0px 4px rgb(106 124 221 / 48%);
}
.accordion-task-selectable:hover {
  background-color: $hovered;
  cursor: pointer;
}
.accordion-caret {
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 200ms;
  color: rgba(0, 0, 0, 0.455);
}
.accordion-caret-active {
  transform: rotate(180deg);
}
