.schedulingForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.schedulingForm > * p {
  margin: 0;
}
.dateTimeFields {
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  flex-direction: column;
}
.recurrencePrompt {
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 0px 0px;
}
// To hide radio-buttons that are incorrectly displayed due to bootstrap bug
.recurrencePrompt > * input[type="radio"] {
  display: none;
}
.recurrenceButtonsGroup {
  display: flex;
  gap: 2rem;
}
.recurrenceButton {
  border-radius: 0.375rem !important;
  padding: 0.6rem 2rem;
}
.recurrenceRepeatRule {
  width: 100%;
  max-width: 767px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
  label {
    min-width: 100px;
  }
}
.recurrenceFields {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
}
.recurrenceFieldRadio {
  display: flex;
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.recurrenceFieldLabel {
  margin: 0px 6px 0px 6px !important;
}
.fieldError {
  color: red;
}
