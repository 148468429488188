/*
===== Brand Colours =====
*/
:root {
    --pyschosocial: #f2c94d;
    --physical: #94fda2;
    --royal-blue: #3b00b8;
}

/* 
===== Dashboard =====
*/
.toggle-dashboard-view-buttons {
    padding: 0px 16px 16px 16px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 30px;
}
.toggle-dashboard-view-buttons > label {
    width: 100%;
    border-radius: 8px !important;
    border: 1px solid #5e72e4;
}
.toggle-dashboard-view-buttons > label:hover {
    z-index: 0 !important;
    background-color: var(--royal-blue) !important;
}
.toggle-dashboard-view-buttons input[type='radio'] {
   display: none; 
}
.toggle-dashboard-view-button-unselected {
    border: 1px solid #5e72e4;
    background-color: transparent !important;
    color: #5e72e4;
}

/* 
===== User Timeline =====
*/

.timeline {
    position: relative;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    border-right: 2px dashed #e9ecef;
}

.timeline-block {
    position: relative;
    margin: 1em 0 0 0;
}

.timeline-block:after {
    content: "";
    display: table;
    clear: both;
}
.timeline-block:first-child {
    margin-top: 0;
}

.timeline-step {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 600;
    z-index: 1;
}

.timeline-one-side .timeline-step, .timeline-one-side:before {
    left: 1rem;
}

.timeline-content {
    margin-left: 60px;
    padding-top: 0.5rem;
    position: relative;
    top: -6px;
}

.timeline-content:after {
    content: "";
    display: table;
    clear: both;
}

.timeline .badge-success    { background-color: #c9f3e1; }
.timeline .badge-info       { background-color: #c3f2fb; }
.timeline .badge-warning    { background-color: #FEE4DE; }
.timeline .badge-danger     { background-color: #fdd7de; }

/*
===== Usage Heatmap =====
*/

.usage-heatmap-empty { fill: #eeeeee; }
.usage-heatmap-0 {fill: #ffffff; }
.usage-heatmap-1 {fill: #CCE6BD; }
.usage-heatmap-2 {fill: #B8DEAA; }
.usage-heatmap-3 { fill: #A1D698; }
.usage-heatmap-4 { fill: #88CE86; }
.usage-heatmap-5 {fill: #74C67A; }
.usage-heatmap-6 { fill: #62BE73; }
.usage-heatmap-7 {fill: #50B56E; }
.usage-heatmap-8 { fill: #3FAC6C; }
.usage-heatmap-9 { fill: #2EA36B; }
.usage-heatmap-10 { fill: #1D9A6C; }
.usage-heatmap-11 { fill: #FFE355; }
.usage-heatmap-12 { fill: #FFC240; }
.usage-heatmap-13 { fill: #FF9F2B; }
.usage-heatmap-14 { fill: #FF7D15; }
.usage-heatmap-15 { fill: #FF5B00; }
.usage-heatmap-16 { fill: #8F0B00; }

.usage-heatmap-scale > *:not(:last-child) {
    margin-right: 2px; 
}

.usage-heatmap-scale small {
    margin-right: 0.25rem; 
} 
.usage-heatmap-period-dropdown .dropdown-toggle {
    font-size: 75%;
    padding: 8%;
}

.react-calendar-heatmap text {
    font-size: 0.45rem;
    font-weight: 500;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 0.5rem;
}

/* 
===== Data Tables =====
*/
.react-bootstrap-table {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 1rem;
}
.react-bootstrap-table th {
    color: #131516;
    background-color: #f6f9fc;
    font-weight: 600;
}
.react-bootstrap-table td {
    color: #131516;
    font-weight: 500;
}

.dataTables_filter {
    font-weight: 400;
    white-space: nowrap;
    text-align: left;
    float: left;
}
.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
}

.react-bootstrap-table .table {
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #e9ecef;
}
.react-bootstrap-table-pagination .dataTables_length label {
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    display: inline-block;
    margin-bottom: 0.5rem;
}
.react-bootstrap-table-pagination .dataTables_length {
    display: inline-block;
}

.dataTables_info, .dataTables_length, .dt-buttons {
    padding-left: 1.5rem;
}

.react-bootstrap-table-pagination .dataTables_length, .react-bootstrap-table-pagination .react-bootstrap-table-pagination-total {
    font-size: .875rem;
}

.react-bootstrap-table-pagination .dataTables_length select.form-control-sm {
    height: calc(1.8125rem + 6px);
}

.react-bootstrap-table-pagination .dataTables_length select {
    width: auto;
    display: inline-block;
}

.dataTables_length .form-control {
    margin: 0 0.375rem;
}

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .pagination {
    justify-content: flex-end;
    padding-right: 1.5rem;
}

/* 
    ===== Table Column Header Carets/Dropdown Icons -- Fix/Workaround =====
    React-bootstrap-table-next has reported issues on adding caret/dropdown icons in the titles of the header columnns.
    The styling below is a workaround for this until support is provided or a better solution is found.
*/
th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .order-4:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
}

th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .caret-4-desc:after {
    content: "\2193";
}
/*
    ===============================================
*/

/*
    Custom Button Color To not Change the Whole Template
    ===============================================
*/

.btn-brand-primary {
    background-color: #3B00B7 !important;
}

.nav-link.active{
    font-weight: 700 !important;
}
.nav-item{
    font-weight: 600 !important;
}

.active.breadcrumb-item{
    color: var(--font-color) !important; 
    font-weight: 600 !important;
}
.breadcrumb-item a{
    color: var(--font-color) !important; 
}


.badge { 
    font-size: 12px !important;
}

.border-dark {
    border: 1px solid #212529 !important; 
}

.border-dark-hb {
    border: 1px solid #FAFAFA !important; 
}

.cursor-pointer {
    cursor: pointer;
}

@supports (position: sticky) {
    .sticky-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020; } 
}

.page-break {
    page-break-before: always; /* Forces a page break before this element */
    page-break-after: always; /* Forces a page break after this element */
    page-break-inside: avoid; /* Avoid breaking inside this element */
}

/* Page print class*/
.page-height-letter {
    position: relative;
    height: 1320px;
    max-height: 1320px;
    min-height: 1320px;
    overflow: hidden; /* Prevent content overflow */
    box-sizing: border-box;
}

.page-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.hover-card {
    position: relative;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fa; /* Optional background for the overall card */
}

.hover-card-body {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: var(--backgroundImage);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.hover-card-body::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Dark overlay */
    transition: background 0.5s ease-in-out;
}

.hover-card:hover .hover-card-body {
    transform: scale(1.1);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Optional hover shadow */
}

.hover-card:hover .hover-card-body::after {
    background: rgba(0, 0, 0, 0.3);
}

.hover-card-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    pointer-events: none;
}

.table-description-details {
    width: 250px;
    max-width: 250px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.table-max-height {
    height: 500px;
}
    .table-max-height table thead tr {
        position: sticky;
        top: 0;
        background-color: white; /* Sets the background to white */
        z-index: 2; /* Ensures the header stays above the table body */
    }
.table-bg-highlighted td {
    background-color: #dcdcfa; /* Base color */
    animation: fadeInOut 3s infinite; /* Run once and retain final state */
    color: #333; /* Text color for contrast */
  }

  /* Define keyframes for the fadeInOut animation */
  @keyframes fadeInOut {
    0% {
      background-color: #dcdcfa; /* Start with base color */
    }
    50% {
      background-color: rgba(223, 223, 235, 0); /* Fade to transparent */
    }
    100% {
      background-color: #dcdcfa; /* Fade back to base color */
    }
  }

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-brand-img {
        max-height: 100% !important;
    }
}

@media (min-height: 700px) {
    .table-max-height {
        height: 600px !important;
    }
}